import React, { useState } from 'react';
import { Accordion, Card } from 'react-bootstrap';
import Icon from '../css/Icon';

const NewApps = ({ programs }) => {
    const [collapseID, setCollapseID] = useState('');

    const toggleCollapse = (id) => {
        if (collapseID !== id) {
            setCollapseID(id);
        }
        else {
            setCollapseID("");
        }
    };
    return (<Accordion id="who-am-I">
        <Accordion.Item eventKey="6">
            <Card style={{ border: 'none', backgroundColor: '#ffffff' }}>
                <Accordion.Header as={Card.Header} onClick={() => toggleCollapse("collapse6")}>
                    <a className="title" href="#!" onClick={(e) => e.preventDefault()}>Affordable Connectivity Pilot Programs Applicant
                        {collapseID === "collapse6" && <Icon name="caret-up" class="indi-form__select-open-icon" />}
                        {collapseID !== "collapse6" && <Icon name="caret-down" class="indi-form__select-open-icon" />}
                    </a>
                </Accordion.Header>
                <Accordion.Body>
                    <Card.Body>
                        <div className="description indi-long-form-text program-body">
                            Submit an application to the Affordable Connectivity Pilot Programs to participate in the Your Home, Your Internet Pilot Program or Navigator Pilot Program.
                        </div>
                        <a title="Register blah blan (opens in new window)" aria-label="Register blah blah" className="indi-button indi-button--primary indi-button--small" target="_blank" href={programs["newACPPilotRegistration"]}>
                            <span className="program">Register</span>
                        </a>
                    </Card.Body>
                </Accordion.Body>
            </Card>
        </Accordion.Item>
        <Accordion.Item eventKey="0">
            <Card style={{ border: 'none', backgroundColor: '#ffffff' }}>
                <Accordion.Header as={Card.Header} onClick={() => toggleCollapse("collapse0")}>
                    <a className="title" href="#!" onClick={(e) => e.preventDefault()}>Service Provider - 498 ID
                        {collapseID === "collapse0" && <Icon name="caret-up" class="indi-form__select-open-icon" />}
                        {collapseID !== "collapse0" && <Icon name="caret-down" class="indi-form__select-open-icon" />}
                    </a>
                </Accordion.Header>
                <Accordion.Body>
                    <Card.Body>
                        <div className="description indi-long-form-text program-body">
                            Register your company with USAC to get an FCC Form 498 ID which is required in order to receive support from universal service fund programs.
                        </div>
                        <a title="Register your service provider company (opens in new window)" aria-label="Register your service provider company" className="indi-button indi-button--primary indi-button--small" target="_blank" href={programs["new498"]}>
                            <span className="program">Register Your Company</span>
                        </a>
                    </Card.Body>
                </Accordion.Body>
            </Card>
        </Accordion.Item>
        <Accordion eventKey="1">
            <Card style={{ border: 'none', backgroundColor: '#ffffff' }}>
                <Accordion.Header as={Card.Header} onClick={() => toggleCollapse("collapse1")}>
                    <a className="title" href="#!" onClick={(e) => e.preventDefault()}>Service Provider - 499 ID
                        {collapseID === "collapse1" && <Icon name="caret-up" class="indi-form__select-open-icon" />}
                        {collapseID !== "collapse1" && <Icon name="caret-down" class="indi-form__select-open-icon" />}
                    </a>
                </Accordion.Header>
                <Accordion.Body>
                    <Card.Body>
                        <div className="description indi-long-form-text program-body">
                            Register your company with USAC to get an FCC Form 499 Filer ID and to start reporting your telecommunications revenues.
                            Note that you must first register your company with the FCC and have an FCC Registration Number (FRN) before registering with USAC.
                        </div>
                        <a title="Register your contributor company (opens in new window)" aria-label="Register your contributor company" className="indi-button indi-button--primary indi-button--small" target="_blank" href={programs["new499"]}>
                            <span className="program">Register Your Company</span>
                        </a>
                    </Card.Body>
                </Accordion.Body>
            </Card>
        </Accordion>
        <Accordion.Item eventKey="2">
            <Card style={{ border: 'none', backgroundColor: '#ffffff' }}>
                <Accordion.Header as={Card.Header} onClick={() => toggleCollapse("collapse2")}>
                    <a className="title" href="#!" onClick={(e) => e.preventDefault()}>Rural Health Care Applicant
                        {collapseID === "collapse2" && <Icon name="caret-up" class="indi-form__select-open-icon" />}
                        {collapseID !== "collapse2" && <Icon name="caret-down" class="indi-form__select-open-icon" />}
                    </a>
                </Accordion.Header>
                <Accordion.Body>
                    <Card.Body>
                        <div className="description indi-long-form-text program-body">
                            If you are new applicant to the Rural Health Care (RHC) program, determine if your health care facility is eligible for
                            Rural Health Care (RHC) Program funding through the Healthcare Connect Fund and/or Telecommunications Program by completing an FCC Form 460 or FCC Form 465.
                        </div>
                        <a title="Get started with RHC application (opens in new window)" aria-label="Get started with RHC application" className="indi-button indi-button--primary indi-button--small" target="_blank" href={programs["newRHC"]}>
                            <span className="program">Get Started</span>
                        </a>
                    </Card.Body>
                </Accordion.Body>
            </Card>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
            <Card style={{ border: 'none', backgroundColor: '#ffffff' }}>
                <Accordion.Header as={Card.Header} onClick={() => toggleCollapse("collapse3")}>
                    <a className="title" href="#!" onClick={(e) => e.preventDefault()}>E-rate Participant
                        {collapseID === "collapse3" && <Icon name="caret-up" class="indi-form__select-open-icon" />}
                        {collapseID !== "collapse3" && <Icon name="caret-down" class="indi-form__select-open-icon" />}
                    </a>
                </Accordion.Header>
                <Accordion.Body>
                    <Card.Body>
                        <div className="description indi-long-form-text program-body">
                            Call CSB at (888)203-8100 if:
                            <ul>
                                <li>You are an applicant and your organization (school, library, consortium, consultant, state coordinator) does not have an EPC account.</li>
                                <li>You are an applicant or a service provider and you want to be added as an account administrator or user on your organization or company’s existing EPC account.</li>
                            </ul>
                            Call USAC Customer Support at (888)641-8722, option 4, if:
                            <ul>
                                <li>You are a new service provider and your company does not have an E-File account.</li>
                                <li>You are a service provider and you want to be added as a user on your company’s E-File account.</li>
                            </ul>
                        </div>
                    </Card.Body>
                </Accordion.Body>
            </Card>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
            <Card style={{ border: 'none', backgroundColor: '#ffffff' }}>
                <Accordion.Header as={Card.Header} onClick={() => toggleCollapse("collapse4")}>
                    <a className="title" href="#!" onClick={(e) => e.preventDefault()}>498 Company Officer
                        {collapseID === "collapse5" && <Icon name="caret-up" class="indi-form__select-open-icon" />}
                        {collapseID !== "collapse5" && <Icon name="caret-down" class="indi-form__select-open-icon" />}
                    </a>
                </Accordion.Header>
                <Accordion.Body>
                    <Card.Body>
                        <div className="description indi-long-form-text program-body">
                            Add a new 498 company officer to an existing 498 account.  After receiving your request,
                            USAC will contact you for supporting banking information to verify the new officer’s credentials.
                            If there is an active company officer(s) on the account, please request the officer set up your credentials in E-File.
                        </div>
                        <a title="Add 498 company officer (opens in new window)" aria-label="Add 498 company officer" className="indi-button indi-button--primary indi-button--small" target="_blank" href={programs["officer498"]}>
                            <span className="program">Add an Officer</span>
                        </a>
                    </Card.Body>
                </Accordion.Body>
            </Card>
        </Accordion.Item>
        <Accordion.Item eventKey="5">
            <Card style={{ border: 'none', backgroundColor: '#ffffff' }}>
                <Accordion.Header as={Card.Header} onClick={() => toggleCollapse("collapse5")}>
                    <a className="title" href="#!" onClick={(e) => e.preventDefault()}>499 Company Officer
                        {collapseID === "collapse5" && <Icon name="caret-up" class="indi-form__select-open-icon" />}
                        {collapseID !== "collapse5" && <Icon name="caret-down" class="indi-form__select-open-icon" />}
                    </a>
                </Accordion.Header>
                <Accordion.Body>
                    <Card.Body>
                        <div className="description indi-long-form-text program-body">
                            Add a new 499 company officer to an existing 499 account.  For this process,
                            you will need your company’s active 499 Filer ID and your most recently filed FCC Form 499-A.
                            If there is an active company officer(s) on the account, please request the officer set up your credentials in E-File.
                        </div>
                        <a title="Add 499 company officer (opens in new window)" aria-label="Add 499 company officer" className="indi-button indi-button--primary indi-button--small" target="_blank" href={programs["officer499"]}>
                            <span className="program">Add an Officer</span>
                        </a>
                    </Card.Body>
                </Accordion.Body>
            </Card>
        </Accordion.Item>
    </Accordion>
    );
}

export default NewApps;